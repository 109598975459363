import { Grid, IconButton, Button } from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import CrossIcon from "@material-ui/icons/Close";
import axios from "axios";
import MaterialTable from "material-table";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tableHeaderStyle } from "../../SharedComponents/SharedComponentStyles";
import { baseApiURL } from "../../Utilities/utility";
import { type } from "jquery";
import { useLocation } from "react-router-dom";


//import Alert from "@material-ui/lab/Alert";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => (
        <Clear {...props} ref={ref} className="bg-transparent" />
    )),
    Search: forwardRef((props, ref) => (
        <Search {...props} ref={ref} className="bg-transparent" />
    )),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const api = axios.create({
      //baseURL: `http://localhost:48934/`
       baseURL: `https://leapuat.si-in.siemens.cloud/`
     //baseURL: `https://leap.si-in.siemens.cloud/`
});





export default function Download() {
    var downloadUrl;
    var columns = [
        /*{ title: "Course Id", field: "courseId", hidden: false },*/
        { title: "Course Name", field: "courseName" },
        { title: "Category", field: "categoryName" },
        { title: "Score", field: "courseScore" },
        { title: "Date", field: "lastDate", type: "date", },
        { title: "Download", field: "courseURL", render: (row) => <div>{row.courseURL !== "" ? <button onClick={() => downloadCertificate(row.courseURL)} state={downloadUrl = row.courseURL} ><ArrowDownwardIcon className="checkIcon" /></button> : < CrossIcon className="crossIcon" />}</div > },

    ];
    const [data, setData] = useState([]); //table data
    const token = useSelector((state) => state.saveTokenReducer.token);
    const location = useLocation();
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    //for error handling

    function downloadCertificate(url) {
        window.location.href = baseApiURL + "/Course/DownloadCertificate?FilePath=" + url;
    }

    function getCompletedList() {
        //const token = localStorage.getItem('access_token');
        fetch(baseApiURL + "/Course/GetUserCompletedCourseList?UserId=" + userDetails.id + "&RoleId=" + userDetails.roleId,
            { headers: { 'Authorization': 'Bearer ' + token } }
        )
            .then(response => response.json())
            .then(data => {
                setData(data.result);
            })
            .catch(error => console.log("Error:", error));
    }
    useEffect(() => {
        if (token) {
            getCompletedList();
        }
    }, [token]);

    return (
        <div className="bg-landing-page">
            <Grid container spacing={1}>
                <Grid item xs={12}>

                    <Grid item xs={12}>
                        <h3> Courses Completed</h3>

                    </Grid>
                    <MaterialTable
                        title=""
                        columns={columns}
                        data={data}
                        icons={tableIcons}
                        options={{
                            headerStyle: tableHeaderStyle,
                            draggable: false,
                        }}

                    />
                </Grid>
            </Grid>
        </div>
    );
}