import {
  AddBox,
  Check,
  DeleteOutline,
  Edit,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft,
  Clear,
  Search,
  ArrowDownward,
  Remove,
  ViewColumn,
} from "@material-ui/icons";
import { forwardRef } from "react";


//Dev
//export const baseApiURL = "http://localhost:48934";
//UAT
export const baseApiURL = "https://leapuat.si-in.siemens.cloud";
//Prod
//export const baseApiURL = "https://leap.si-in.siemens.cloud";

export function decodeData(value) {
    const { atob } = require("abab");
    let arrValue = atob(atob(value)).split(" ");
    let valueActual = arrValue[0];
    return valueActual;
}

